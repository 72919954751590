<template>
  <div>
    <div class="text-2xl font-normal">
      Edit Profile
    </div>
    
    <div class="flex gap-10 pt-8">
      <div>
        <img
          src="https://picsum.photos/264/320"
          alt="Profile picture"
          class="rounded-md"
        >

        <a class="flex justify-center align-center text-gray-500 pt-2 cursor-pointer">
          <UploadIcon class="w-5 h-5 mr-2" />
          change photo
        </a>
      </div>

      <div class="w-96 flex flex-col justify-between">
        <div>
          <div>
            <label
              for="name"
              class="block text-sm font-medium text-gray-700"
            >NAME</label>

            <div class="mt-1">
              <input
                type="text"
                name="name"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Name"
              >
            </div>
          </div>

          <div class="pt-5">
            <label
              for="email"
              class="block text-sm font-medium text-gray-700"
            >EMAIL</label>

            <div class="mt-1">
              <input
                type="email"
                name="email"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="john@ron.digital"
              >
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="w-24 mb-8 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { UploadIcon } from '@heroicons/vue/outline'

export default {
  components: {
    UploadIcon,
  },
};
</script>